import useOnScreen from 'hooks/useOnScreen'
import { SectionProvider } from 'providers/SectionProvider'
import React from 'react'

export function ChildComponentGenerator({
  contentTypes,
  uiComponent,
  isCrawler = false,
  lazyLoad,
  ...props
}) {
  // useOnScreen is a custom hook that returns a ref and a boolean indicating whether the ref is currently in the viewport
  const { ref, isIntersecting } = useOnScreen()

  // If uiComponent or contentTypes is not provided, return null
  if (!uiComponent || !contentTypes) {
    return null
  }

  // Get the component to render based on the uiComponent prop
  const COMPONENT = contentTypes[uiComponent]

  // If the component is not found, return null
  if (!COMPONENT) {
    return null
  }

  // Create the section to render, wrapped in a SectionProvider
  const section = (
    <SectionProvider
      variation={
        { ...props?.fields?.experiment, variant: props?.fields?.experimentVariation }
      }
    >
      {React.createElement(COMPONENT, {
        content: { ...props, isCrawler },
      })}
    </SectionProvider>
  )

  // If lazyLoad is true, wrap the section in a div that only renders its children when it's in the viewport
  // If lazyLoad is not true, render the section directly
  return lazyLoad ? (
    <div ref={ref} data-testid={`lazy-loaded-${uiComponent}`}>
      {isIntersecting && section}
    </div>
  ) : (
    section // Render the section directly
  )
}
