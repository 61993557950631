import classNames from 'classnames'
import { Typography } from 'components/Contentful'
import styles from './Eyebrow.module.scss'

function Eyebrow({ className, black = false, variant = 'bodySm', ...restProps }) {
  return (
    <Typography
      variant={variant}
      fontWeight="bold"
      className={classNames('group', styles['eyebrow'], className)}
      data-variant={black ? 'black' : 'default'}
      {...restProps}
    />
  )
}

export { Eyebrow }
